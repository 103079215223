<template>
  <v-container>
    <v-row
      class="mb-7 fade-transition"
      justify="center"
      no-gutters
    >
      <v-col
        cols="12"
        sm="11"
      >
        <base-material-card
          icon="mdi-hammer-wrench"
          class="pa-3 text-end"
          color="secondary"
        >
          <v-row
            justify="center"
          >
            <button-file-ngrok />
            <button-file-tentacle />
          </v-row>
          <v-row
            no-gutters
            align="center"
            justify="center"
          >
            <v-container fluid>
              <v-row>
                <v-col cols="12">
                  <v-combobox
                    v-model="selectedCustomers"
                    :items="names"
                    :loading="loading"
                    item-text="displayName"
                    label="Buscar cliente"
                    return-object
                    multiple
                    deletable-chips
                    clearable
                    chips
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-row>
          <div
            v-if="selectedCustomers.length"
          >
            <v-row
              justify="center"
              align="center"
            >
              <button-config-tentacle
                v-model="selectedCustomers"
              />
              <button-config-ngrok
                v-model="selectedCustomers"
              />
            </v-row>
          </div>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  export default {
    name: 'ConfigServers',
    components: {
      ButtonConfigTentacle: () => import('./components/buttons/ConfigTentacle.vue'),
      ButtonFileNgrok: () => import('./components/buttons/FileNgrok.vue'),
      ButtonFileTentacle: () => import('./components/buttons/FileTentacle.vue'),
      ButtonConfigNgrok: () => import('./components/buttons/ConfigNgrok.vue'),
    },
    data () {
      return {
        names: [],
        loading: true,
        selectedCustomers: [],
      }
    },
    computed: {
      ...mapGetters('customers', ['customerList']),
    },
    created: function () {
      this.searchClient()
    },
    methods: {
      ...mapActions('customers', ['listCustomers']),
      async searchClient () {
        await this.listCustomers()
        this.names = this.customerList.map(user => ({
          displayName: `${user.code} - ${user.name} - ${user._id}`,
          ...user,
        }))
        this.loading = false
      },
    },
  }
</script>
